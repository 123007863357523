import './download.scss';
import instagram from '../assets/instagram.png';
import facebook from '../assets/facebook.png';
import tiktok from '../assets/tiktok.png';
import savartBg from '../assets/savart-bg.jpg';
import appsBg from '../assets/apps-bg.jpg';
import savartWhite from '../assets/savart-white.png';

type Menu = {
  sublabel?: string;
  label: string;
  link: string;
  icon?: React.ReactNode;
};

const menu: Menu[] = [
  {
    label: 'Google Play',
    link: 'https://mobile.savart-ev.com/android/savart.apk',
    icon: (
      <div className="w-5 h-5 transition-colors fill-white group-hover:fill-inherit">
        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <title>Google Play</title>
          <path d="M22.018 13.298l-3.919 2.218-3.515-3.493 3.543-3.521 3.891 2.202a1.49 1.49 0 0 1 0 2.594zM1.337.924a1.486 1.486 0 0 0-.112.568v21.017c0 .217.045.419.124.6l11.155-11.087L1.337.924zm12.207 10.065l3.258-3.238L3.45.195a1.466 1.466 0 0 0-.946-.179l11.04 10.973zm0 2.067l-11 10.933c.298.036.612-.016.906-.183l13.324-7.54-3.23-3.21z" />
        </svg>
      </div>
    ),
  },
  {
    label: 'App Store',
    link: 'https://appho.st/d/Hol1y7bV',
    icon: (
      <svg
        className="w-6 h-6 transition-colors group-hover:fill-black fill-white"
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Apple</title>
        <path d="M12.152 6.896c-.948 0-2.415-1.078-3.96-1.04-2.04.027-3.91 1.183-4.961 3.014-2.117 3.675-.546 9.103 1.519 12.09 1.013 1.454 2.208 3.09 3.792 3.039 1.52-.065 2.09-.987 3.935-.987 1.831 0 2.35.987 3.96.948 1.637-.026 2.676-1.48 3.676-2.948 1.156-1.688 1.636-3.325 1.662-3.415-.039-.013-3.182-1.221-3.22-4.857-.026-3.04 2.48-4.494 2.597-4.559-1.429-2.09-3.623-2.324-4.39-2.376-2-.156-3.675 1.09-4.61 1.09zM15.53 3.83c.843-1.012 1.4-2.427 1.245-3.83-1.207.052-2.662.805-3.532 1.818-.78.896-1.454 2.338-1.273 3.714 1.338.104 2.715-.688 3.559-1.701" />
      </svg>
    ),
  },
];

const socmed = [
  { label: 'Instagram', link: 'https://www.instagram.com/savartmotors/', image: instagram, imgClassname: 'h-5' },
  { label: 'Facebook', link: 'https://www.facebook.com/savartmotors/', image: facebook },
  { label: 'TikTok', link: 'https://www.tiktok.com/@savartmotors', image: tiktok },
];

export default function DownloadPage() {
  return (
    <main className="relative flex flex-col justify-end w-screen" style={{ height: window.innerHeight }}>
      <img src={appsBg} alt="apps-bg" className="absolute top-0 left-0 object-cover w-full h-full vignette -z-10" />
      <div className="absolute w-full h-full -z-10 vignette" />
      <div className="flex flex-col justify-between flex-1 p-12 sm:conatiner sm:px-32">
        <img src={savartWhite} alt="savart-white" className="w-32 mx-auto" />
        <div>
          <h1 className="text-white poppins-regular subtitle">The Future of Mobility is Here</h1>
          <h1 className="text-white poppins-bold title">SAVART Apps</h1>
          <h2 className="mt-16 text-white poppins-regular subtitle">Available now on</h2>
          <div className="flex flex-col gap-3 mt-4">
            {menu.map((item, idx) => (
              <a
                href={item.link}
                key={idx}
                className="group text-center no-underline hover:cursor-pointer text-sm poppins-bold w-full h-12 text-[#ffffff] rounded-full border border-white hover:bg-white hover:text-black transition-all"
              >
                <div className="flex flex-col justify-center h-full">
                  {item.sublabel && <div className="text-[0.625rem] font-light">{item.sublabel}</div>}
                  <div className="flex items-center justify-center gap-3">
                    {item.icon}
                    <div>{item.label}</div>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <div className="flex flex-row items-center gap-4 justify-center mt-[3rem]">
            <div className="text-xs text-white">Our Social Media</div>
            {socmed.map((item, idx) => (
              <a key={idx} href={item.link} className="hover:cursor-pointer">
                <img src={item.image} alt={item.label} className={item.imgClassname ?? 'h-6'} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
